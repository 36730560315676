import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  Checkbox,
  IIconProps,
  PrimaryButton,
  Stack,
  TextField,
  Toggle,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { CustomDropdown } from 'common/components/CustomDropdown';
import { FilterArrayType } from 'common/components/Filters';
import {
  TransferCycles,
  TransferCyclesVariables,
} from 'common/graphql/__generated__/TransferCycles';
import {
  EntityDeleteInput,
  EntitySelectionInput,
  GenerateIntegrationStatusType,
  PurchaseOrderExportInput,
  SearchRequestInput,
} from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useToasts } from 'react-toast-notifications';
import { PurchaseOrderItem } from '..';
import { toFilterVariableExport } from '../utils';
import {
  GenerateIntegrationStatusSub,
  GenerateIntegrationStatusSubVariables,
} from './__generated__/GenerateIntegrationStatusSub';
import {
  GeneratePurchaseOrderIntegration,
  GeneratePurchaseOrderIntegrationVariables,
} from './__generated__/GeneratePurchaseOrderIntegration';
import { PurchaseOrderAccountingPeriods } from './__generated__/PurchaseOrderAccountingPeriods';
import {
  PurchaseOrderExport,
  PurchaseOrderExportVariables,
} from './__generated__/PurchaseOrderExport';
import {
  PurchaseOrderExportSearch,
  PurchaseOrderExportSearchVariables,
} from './__generated__/PurchaseOrderExportSearch';
import { useStyles } from './index.styles';

const INVOICE_SIGNING_EXPORT = loader('./PurchaseOrderExport.graphql');
const ACCOUNTING_PERIOD = loader('./PurchaseOrderAccountingPeriods.graphql');
const TRANSFER_CYCLE = loader('../../../common/graphql/transferCycles.graphql');
const PURCHASE_ORDER_INTEGRATION = loader(
  './GeneratePurchaseOrderIntegration.graphql'
);
const INTEGRATION_STATUS = loader('./GenerateIntegrationStatusSub.graphql');
const PURCHASE_ORDER_EXPORT_SEARCH = loader(
  './PurchaseOrderExportSearch.graphql'
);

const exportIcon: IIconProps = {
  iconName: 'Go',
  styles: {
    root: {
      fill: 'white',
      marginRight: 10,
    },
  },
};

interface CsvButtonProps {
  onCallBack?: () => void;
  selectedRows: PurchaseOrderItem[];
  isSigningSearch: boolean;
  searchFilters: FilterArrayType[];
  purchaseOrderDocumentSearchRequest: SearchRequestInput | null;
  isHistorySearch: boolean;
  isSearchAllData: boolean;
  isLinkSearch: boolean;
  isSelectedAllRows: boolean;
}

export const ExportPurchaseOrder: React.FC<CsvButtonProps> = ({
  onCallBack,
  selectedRows,
  isSigningSearch,
  searchFilters,
  purchaseOrderDocumentSearchRequest,
  isHistorySearch,
  isSearchAllData,
  isLinkSearch,
  isSelectedAllRows,
}) => {
  const styles = useStyles();
  const client = useApolloClient();
  const { addToast } = useToasts();
  const [accountingTransfer, setAccountingTransfer] = useState<boolean>(false);
  const [hideConfirmRequestDialog, { toggle: toggleConfirmDialog }] =
    useBoolean(true);
  const [includeAccDistro, setIncludeAccDistro] = useState<
    boolean | undefined
  >();
  const [stampDescription, setStampDescription] = React.useState<string>('');
  const csvLink = useRef<HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [selectedAccountingOption, setSelectedOption] = useState<string | null>(
    null
  );

  const [csvData, setCsvData] = useState<string[][]>([]);
  const [csvFileName, setCsvFileName] = useState<string>();

  const [getDocumentIdsArray] = useLazyQuery<
    PurchaseOrderExportSearch,
    PurchaseOrderExportSearchVariables
  >(PURCHASE_ORDER_EXPORT_SEARCH, {
    onCompleted: (data) => {
      if (data.purchaseOrderExportSearch) {
        const entitySelectionArray: EntitySelectionInput[] =
          data.purchaseOrderExportSearch
            .filter((item) => item !== null)
            .map((item) => ({
              id: item?.id,
              rowTimestamp: item?.rowTimestamp,
            }));
        getIntegrationDocId(entitySelectionArray);
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [generateIntegrationDocId] = useMutation<
    GeneratePurchaseOrderIntegration,
    GeneratePurchaseOrderIntegrationVariables
  >(PURCHASE_ORDER_INTEGRATION, { errorPolicy: 'all' });
  const [documentIds, setDocumentIds] = useState<EntitySelectionInput[] | null>(
    []
  );
  useEffect(() => {
    const newDocumentIds = selectedRows.map(({ id, _rowTimestamp }) => ({
      id,
      rowTimestamp: _rowTimestamp,
    }));

    setDocumentIds(newDocumentIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const STAMPING_TITLE =
    'Enter the description and press confirm to download the Purchase order worksheet.';

  const [purchaseOrderExport] = useMutation<
    PurchaseOrderExport,
    PurchaseOrderExportVariables
  >(INVOICE_SIGNING_EXPORT);

  const { data: accoutringPeriods } = useQuery<PurchaseOrderAccountingPeriods>(
    ACCOUNTING_PERIOD,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const [transferCycle, { data: nextCycleData }] = useLazyQuery<
    TransferCycles,
    TransferCyclesVariables
  >(TRANSFER_CYCLE, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const accountingPeriodOptions = useMemo(
    () =>
      accoutringPeriods?.companyCorporatePeriods?.nodes.map((item) => ({
        disabled: item.isEntryAllowed ? false : true,
        key: item.id,
        text:
          item._periodYear +
            ' (' +
            dateFormat(dateConvertions(item.startDate)) +
            ' - ' +
            dateFormat(dateConvertions(item.endDate)) +
            ')' || '',
      })) || [],
    [accoutringPeriods]
  );

  useEffect(() => {
    setSelectedOption(accountingPeriodOptions[0]?.key);
  }, [accountingPeriodOptions]);

  const handleConfirmDownloadPress = async () => {
    const rows: EntityDeleteInput[] = [];
    for (let data of selectedRows) {
      rows.push({
        id: data?.id,
        rowTimestamp: data?._rowTimestamp!,
      });
    }
    const exportVariable: PurchaseOrderExportInput = {
      exportDescription: stampDescription,
      entityDelete: rows!,
      corporatePeriodId: selectedAccountingOption,
      isDistributionIncluded: includeAccDistro,
    };
    const { errors, data } = await purchaseOrderExport({
      variables: {
        input: exportVariable,
      },
    });
    toggleConfirmDialog();
    setAccountingTransfer(false);
    if (!errors) {
      const { exportResult } = { ...data?.purchaseOrderExport };
      if (exportResult?.isRefreshListviewRequired) {
        onCallBack?.();
      }
      if (
        exportResult?.isCreateLocalFilesRequired &&
        exportResult?.exportCycles
      ) {
        const idArray = exportResult.exportCycles.map((item) => item as string);
        transferCycle({
          variables: {
            idArray,
          },
        });
      }
    }

    if (errors) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
      setStampDescription('');
    }
  };

  useEffect(() => {
    const extractData = (str: string) => {
      return str.split('"').filter((e, i) => i & 1);
    };
    nextCycleData?.transferCycles?.nodes.forEach((item, index) => {
      const csvFormattedData: string[][] = [];
      if (item) {
        if (csvLink?.current) {
          setTimeout(() => {
            setCsvFileName(item?._exportFilename!);
            csvFormattedData.push(extractData(item?.transferHeaders!));
            for (let col of item.transactionTransfers.nodes) {
              csvFormattedData.push(extractData(col?.transferValues!));
            }
            setCsvData(csvFormattedData!);
            setStampDescription('');
            csvLink.current?.link?.click();
          }, index * 500);
        }
      }
    });

    if (!!nextCycleData?.transferCycles?.nodes.length) {
      addToast('CSV Exported', {
        appearance: 'info',
      });
    }
  }, [addToast, nextCycleData]);

  const handleExportToFolder = async () => {
    const filterVariable = toFilterVariableExport(searchFilters ?? []);
    if (isSelectedAllRows) {
      getDocumentIdsArray({
        variables: {
          searchRequest: purchaseOrderDocumentSearchRequest,
          isHistorySearch,
          isLinkSearch,
          isSearchAllData,
          isSigningSearch,
          ...filterVariable,
        },
      });
    } else {
      if (documentIds) getIntegrationDocId(documentIds);
    }
  };
  const getIntegrationDocId = async (documentIDs: EntitySelectionInput[]) => {
    if (documentIds) {
      const { errors, data } = await generateIntegrationDocId({
        variables: {
          input: {
            entitySelection: documentIDs,
          },
        },
      });
      toggleConfirmDialog();
      setAccountingTransfer(false);
      if (!!errors?.length) {
        addToast(errors?.[0].message, { appearance: 'error' });
      } else {
        if (!!data?.generatePurchaseOrderIntegration?.integrationDocumentId) {
          addToast(`Export process submitted.`, {
            appearance: 'info',
          });
          const integrationDocumentId =
            data?.generatePurchaseOrderIntegration?.integrationDocumentId;
          const observer = client.subscribe<
            GenerateIntegrationStatusSub,
            GenerateIntegrationStatusSubVariables
          >({
            query: INTEGRATION_STATUS,
            variables: {
              integrationDocumentId: integrationDocumentId,
            },
          });
          const subscription = observer.subscribe(({ data, errors }) => {
            if (errors) {
              addToast(
                'Errors received while Subscribing to generate integration status',
                {
                  appearance: 'error',
                }
              );
            } else {
              const { status } = {
                ...data?.generateIntegrationStatus,
              };
              if (status === GenerateIntegrationStatusType.SUCCESS) {
                addToast(
                  'Export file successfully created in the export folder',
                  {
                    appearance: 'success',
                  }
                );
              }
              if (status === GenerateIntegrationStatusType.FAILURE) {
                addToast(
                  'Error occurred while creating export file in the export folder',
                  {
                    appearance: 'error',
                  }
                );
              }
            }
            subscription.unsubscribe();
          });
        }
      }
    }
  };

  return (
    <>
      {!!selectedRows.length && (
        <PrimaryButton
          text="Export"
          onClick={toggleConfirmDialog}
          iconProps={exportIcon}
        />
      )}
      {csvData ? (
        <CSVLink
          data={csvData}
          filename={csvFileName}
          target="_blank"
          ref={csvLink}
        ></CSVLink>
      ) : null}

      <ConfirmDialog
        isConfirmPrimaryButton
        hidden={hideConfirmRequestDialog}
        title={STAMPING_TITLE}
        onDismiss={() => {
          toggleConfirmDialog();
          setAccountingTransfer(false);
        }}
        minWidth={500}
        onConfirm={
          accountingTransfer ? handleExportToFolder : handleConfirmDownloadPress
        }
      >
        <Stack tokens={{ childrenGap: 20 }}>
          <TextField
            className={styles.marginT10}
            rows={3}
            value={stampDescription}
            onChange={(_event, value) => setStampDescription(value || '')}
            placeholder="Worksheet description"
            resizable={true}
          />
          <CustomDropdown
            label="Accounting Period"
            placeholder="Select"
            selectedKey={selectedAccountingOption}
            options={accountingPeriodOptions}
            onChange={(_, option) => {
              setSelectedOption(option?.key + '');
            }}
            onClear={() => {
              setSelectedOption(null);
            }}
          />

          <Checkbox
            label="Include Account distribution"
            onChange={(ev, value) => {
              if (ev) {
                setIncludeAccDistro(value);
              }
            }}
          />
          <Toggle
            inlineLabel
            label="Accounting Transfer"
            disabled={isSigningSearch || isHistorySearch}
            onChange={(_, value) => {
              setAccountingTransfer(!!value);
            }}
          />
        </Stack>
      </ConfirmDialog>
    </>
  );
};
